import "../App.css";
import { useState, useEffect } from "react";
import HomeContainer from "./HomeContainer";
import Login from "./Login";

function App() {
  const [isLogin, setIsLogin] = useState(false);

  const handleLogin = () => {
    // add to session
    setIsLogin(true);
    sessionStorage.setItem("isLogin", true);

    //temp fix for dropdown bug
    // window.location.reload()
  };

  useEffect(() => {
    const session = sessionStorage.getItem("isLogin");
    setIsLogin(session);
  }, []);

  return (
    <div className="">
      {isLogin ? <HomeContainer /> : <Login onLogin={handleLogin} />}
    </div>
  );
}

export default App;
