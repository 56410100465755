import React, { useState } from "react";

const ImageDownloader = () => {
  const [imageUrls, setImageUrls] = useState("");

  const downloadImages = () => {
    const urls = imageUrls.split(",").map((url) => url.trim());

    urls.forEach((link, index) => {
      fetch(`http://localhost:3000/image-proxy?url=${encodeURIComponent(link)}`)
        .then((response) => {
            console.log("RESPONSE", response)
          if (!response.ok) {
            throw new Error(
              `Failed to fetch ${link}, status: ${response.status}`
            );
          }
          return response.blob();
        })
        .then((blob) => {
            console.log("BLOB", blob)
          //image_${index}.png`
          const filename = `image_${index}.jpg`;
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          console.log(`Downloaded ${filename} successfully.`);
        })
        .catch((error) => {
          console.error(`Failed to download image_${index}.png:`, error);
        });
    });
  };

  return (
    <div className="pt-32 flex flex-col items-center justify-center">
      <textarea
        value={imageUrls}
        onChange={(e) => setImageUrls(e.target.value)}
        placeholder="Enter image URLs separated by commas"
        rows={4}
        cols={50}
        className="rounded-md"
      />
      <br />
      <button
        onClick={downloadImages}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-xs px-5 py-2.5 text-center"
      >
        Download
      </button>
    </div>
  );
};

export default ImageDownloader;
