
const CsvProduct = ({ p, onDelete }) => {


  return (
    <>
      <tr key={p.id}>
        <td className="py-2">
          <img
            className="m-auto h-12 rounded-t-lg md:rounded-none md:rounded-s-lg"
            src={p.product_image_1 === "" ? p.logo_image : p.product_image_1}
            alt=""
          />
        </td>
        <td className="p-2 font-semibold text-gray-900 dark:text-white">
          {p.brand}
        </td>
        <td className="p-2">{p.sku}</td>
        <td className="p-2">{p.product_eng}</td>
        <td className="p-2">{p.rrp_with_vat}</td>
        <td className="p-2">
          <svg
            onClick={() => onDelete(p)}
            className=" w-6 h-6 text-gray-800 dark:text-white cursor-pointer"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
            />
          </svg>
        </td>
      </tr>
    </>
  );
};

export default CsvProduct;
