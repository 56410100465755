import { products } from "./productsHook";
import { newProducts } from "./newArrivalsHook";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import banner2 from "../images/banner.jpg";

const ProductCard = ({ category, brand }) => {
  const navigate = useNavigate();

  const categorizedProducts =
    category === "New Arrivals"
      ? newProducts
      : products.filter((p) => {
          if (
            category !== "View All" &&
            brand !== "View All" &&
            category !== "New Arrivals"
          ) {
            return p.category_1 === category && p.brand === brand;
          } else if (category !== "View All" && category !== "New Arrivals") {
            return p.category_1 === category;
          } else if (brand !== "View All") {
            return p.brand === brand;
          } else {
            return true; // No filtering needed if both category and brand are "All"
          }
        });

  // console.log(categorizedProducts);

  const [savedProducts, setSavedProducts] = useState([]);

  // State to keep track of the number of products to display
  const [visibleProducts, setVisibleProducts] = useState(30);

  // Function to load more products
  const loadMoreProducts = () => {
    // Increment the number of visible products by 30
    setVisibleProducts(visibleProducts + 30);
  };

  useEffect(() => {
    const storedProducts = sessionStorage.getItem("products");
    if (storedProducts) {
      setSavedProducts(JSON.parse(storedProducts));
    }
  }, []);

  return (
    <>
      {/* {category === "View All" && brand === "View All" ? (
        <img src={banner2} alt="banner" className="pt-20" />
      ) : null} */}
      {/* ${category === "View All" && brand === "View All" ? "" : "pt-20"} */}
      <img src={banner2} alt="banner" className="pt-20" />
      <div className={`grid grid-cols-5 mx-4 `}>
        {categorizedProducts.slice(0, visibleProducts).map((p) => (
          <div
            key={p.id}
            className="cursor-pointer mt-3 mr-3 p-3 max-w-sm bg-white shadow"
          >
            <div className="rounded-t-lgh-64 ">
              <img
                onClick={() => {
                  navigate(p.id);
                }}
                className="object-contain h-64 m-auto rounded-t-lg p-3"
                src={
                  p.product_image_1 === "" ? p.logo_image : p.product_image_1
                }
                alt="products"
              />
            </div>
            <div className="p-3">
              <h5 className="mb-2 text-md font-bold tracking-tight text-gray-900">
                {p.product_eng}
              </h5>

              {/* Price & Button to add to csv */}
              <div className="flex items-center justify-between mt-4">
                <span className="text-sm font-bold text-gray-900">
                  {p.rrp_with_vat}
                </span>
                <button
                  onClick={() => {
                    // console.log("product is", p)
                    setSavedProducts([...savedProducts, p]);
                    sessionStorage.setItem(
                      "products",
                      JSON.stringify([...savedProducts, p])
                    );
                  }}
                  disabled={savedProducts.some((pro) => pro.id === p.id)}
                  className={
                    savedProducts.some((pro) => pro.id === p.id)
                      ? "cursor-default text-blue-700 bg-gray-100 border-grey-700 font-medium rounded-md text-xs px-5 py-2.5 text-center"
                      : "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-xs px-5 py-2.5 text-center"
                  }
                >
                  Add to CSV
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {category === "View All" && brand === "View All" ? (
        <div className="flex justify-center items-center mt-4">
          <p className="text-xs m-auto">
            You've viewed {visibleProducts} of {products.length} products
          </p>
        </div>
      ) : null}
      {visibleProducts < categorizedProducts.length && (
        <div className="flex justify-center items-center">
          <button
            onClick={loadMoreProducts}
            className="w-80 py-4 px-5 me-2 m-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
          >
            Load More Products
          </button>
        </div>
      )}
    </>
  );
};

export default ProductCard;
