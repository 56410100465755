import CsvProduct from "./CsvProduct";

const CsvCard = ({ formattedProducts, onDelete }) => {
  return (
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="px-2 py-3">
            Image
          </th>
          <th scope="col" className="px-2 py-1">
            Brand
          </th>
          <th scope="col" className="px-2 py-1">
            SKU
          </th>
          <th scope="col" className="px-2 py-1">
            Product
          </th>
          <th scope="col" className="px-2 py-1">
            Price
          </th>
          <th scope="col" className="px-2 py-1">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {formattedProducts
          .sort((a, b) => a.brand.localeCompare(b.brand))
          .map((p) => (
            <CsvProduct key={p.id} p={p} onDelete={onDelete} />
          ))}
      </tbody>
    </table>
  );
};

export default CsvCard;
