import { products } from "./productsHook";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const Product = () => {
  const [savedProducts, setSavedProducts] = useState([]);

  let navigate = useNavigate();

  let { productId } = useParams();
  const selectedProduct = products.find((p) => p.id === productId);

  const [selectedImage, setSelectedImage] = useState(
    selectedProduct.product_image_1
  );

  useEffect(() => {
    const storedProducts = sessionStorage.getItem("products");
    if (storedProducts) {
      setSavedProducts(JSON.parse(storedProducts));
    }
  }, []);

  return (
    <>
      <div style={{ paddingTop: "100px" }} className="ml-20">
        <button onClick={() => navigate("/")} className="border rounded p-3 flex items-center">
          <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
          />
        </svg>
          Back
        </button>
      </div>
      {selectedProduct ? (
        <div className="flex items-start justify-between mx-20">
          {/* Small images on the right to select*/}
          {selectedProduct.product_image_1 && (
            <div className="w-20 h-20 mt-10 cursor-pointer">
              {[1, 2, 3, 4].map((index) => {
                const productImage = selectedProduct[`product_image_${index}`];
                if (productImage) {
                  return (
                    <div
                      key={index}
                      className="w-20 h-20 mt-2 border rounded-md"
                      style={
                        selectedImage === productImage
                          ? { border: "2px solid black", padding: "1px" }
                          : {} // No border style applied
                      }
                    >
                      <img
                        onClick={() => setSelectedImage(productImage)}
                        className="h-20 mx-auto pb-2 pt-1 rounded-lg"
                        src={productImage}
                        alt="product"
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}

          {/* Preview images */}
          <div className="">
            <img
              className="object-contain h-[70vh] m-auto rounded-t-lg max-w-sm"
              src={
                selectedImage === ""
                  ? selectedProduct.logo_image
                  : selectedImage
              }
              alt="products"
            />
          </div>

          {/* Product Description */}
          <div className="pt-8  max-w-lg">
            <p className="text-base">{selectedProduct.brand}</p>
            <p className="text-2xl mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
              {selectedProduct.product_eng}
            </p>
            <p className="mb-6 text-xs text-gray-900 dark:text-white">
              SKU: {selectedProduct.sku}
            </p>
            <p className="mb-6 text-base text-gray-900 dark:text-white">
              {selectedProduct.rrp_with_vat}
            </p>
            <button
              onClick={() => {
                setSavedProducts([...savedProducts, selectedProduct]);
                sessionStorage.setItem(
                  "products",
                  JSON.stringify([...savedProducts, selectedProduct])
                );
              }}
              disabled={savedProducts.some((p) => p.id === selectedProduct.id)}
              className={
                savedProducts.some((p) => p.id === selectedProduct.id)
                  ? "cursor-default w-full text-blue-700 bg-gray-100 border-grey-700 font-medium rounded-md mb-10 text-sm py-3"
                  : "w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm mb-10 py-3 text-center"
              }
            >
              Add to CSV
            </button>

            <div
              id="accordion-flush"
              data-accordion="collapse"
              data-active-classes="max-w-md bg-white dark:bg-gray-900 text-black dark:text-white"
              data-inactive-classes="max-w-md text-black dark:text-gray-400"
            >
              <h2 id="accordion-flush-heading-1">
                <button
                  type="button"
                  className="flex items-center justify-between w-full py-3 font-medium rtl:text-right border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 gap-3"
                  data-accordion-target="#accordion-flush-body-1"
                  aria-expanded="false"
                  aria-controls="accordion-flush-body-1"
                >
                  <span className="text-sm text-black">Product Details</span>
                  <svg
                    data-accordion-icon
                    className="w-3 h-3 shrink-0"
                    aria-hidden="false"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1 5 5 9 1"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-flush-body-1"
                // className="hidden"
                aria-labelledby="accordion-flush-heading-1"
              >
                <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                  <p className="text-sm max-w-md text-gray-500 dark:text-gray-400">
                    {selectedProduct.description_eng}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Product;
