import { useEffect, useState } from "react";
import CsvCard from "./CsvCard";
import { CSVLink } from "react-csv";

const CsvContainer = () => {
  const date = new Date();
  const formattedDate = `${
    monthNames[date.getMonth()]
  }.${date.getDate()}.${date.getFullYear()}`;

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const storedProducts = sessionStorage.getItem("products");
    setProducts(JSON.parse(storedProducts));
  }, []);

  const handleDelete = (p) => {
    const updatedProducts = products.filter((product) => {
      return product.id !== p.id;
    });

    setProducts(updatedProducts);
    sessionStorage.setItem("products", JSON.stringify(updatedProducts));
  };

  const productsWithoutId = products
    ?.sort((a, b) => a.brand.localeCompare(b.brand))
    .map(({ id, ...rest }) => rest);
    console.log(productsWithoutId)

  return (
    <div className="px-6 ">
      {products === null || products.length === 0 ? (
        <>
          <div className="h-screen flex items-center justify-center ">
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-11 h-11"
                style={{ margin: "auto" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                />
              </svg>
              <p className="mt-5">Your CSV is empty. Please add products.</p>
            </div>
          </div>
        </>
      ) : (
        <div className="pt-28">
          <div className="flex justify-end">
            <CSVLink
              data={productsWithoutId}
              filename={`Nagano_Rewards_JP_Catalogue_${formattedDate}.csv`}
            >
              <button
                type="button"
                className="px-5 py-3 mb-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm focus:outline-none"
              >
                Export to CSV
              </button>
            </CSVLink>
          </div>
          <div className="overflow-x-auto shadow-md sm:rounded-lg">
            <CsvCard formattedProducts={products} onDelete={handleDelete} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CsvContainer;

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
